/* @import "./../../styles/material-colors.scss";} */

 
.login-page{
    .form-login {
        width: 100%;
        max-width: 500px;
        padding-left: 115px;
        padding-top: 15px;
        margin: auto;              
    
        .form-control {
            position: relative;
            box-sizing: border-box;
            // height: auto;            
            padding: 4px;
            font-size: 14px;
    
            &:focus {
                z-index: 2;
            }
        }        
    
        .username {
            margin-bottom: 10px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    
        .password {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;                     
        }

        .label {
            margin-bottom: 2px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;            
            display: block;            
        }

        .input-group {
            width: 100%;                      
        }

        .displayBadge{
            margin-top: 4px; 
            margin-right:200px;
            display: none; 
            text-align :center;
            color :white;  
            box-sizing: border-box;
          }
    
    }  
} 