/* @vontobel-dark : #373a36; */

/* @import url(); */

* {
  margin : 0;
  padding : 0;
  box-sizing: border-box;
  /* font-family:'Courier New', Courier, monospace */
}

.form-control, .konto-select {
  position: relative;
  box-sizing: border-box;
  height: auto;  
  padding: 2px;
  font: 14px Tahoma;  
  width:100%;
}

body {
  height : 100vh;
  background-color: white; /* ivory; */
  /* font-family: "Poppins", sans-serif;  */
  /* color: #77c5d5 */
  /* color: #373a36; */
  
}

.help_div {  
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;  
}

table tr.liste:nth-child(2n+1) {
  background-color: #ccc;   
}

table tr.MuiTableRow-root:nth-child(2n+1)
{
  background-color: #eee; /* #e4f0ec; */
}

table tr.MuiTableRow-root:selected
{
  background-color: '#666';
}

/* .mat-row:not(:nth-child(4n+1))
{
  background-color:#ffffff;
} */

a,
button {
  /* font-family: "Poppins", sans-serif; */
}

/* .react-datepicker-clearbutton {
  background-color: red;   
} */

/* .react-datepicker__close-icon {
  top: 25%;
  right: 7px;
  width: 32px;
  height: 16px !important;
  position: absolute;
} */
/* .react-datepicker__close-icon::after {
  right: 0 !important;
  top: 0;
  margin: 0;
} */
/*
 .react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 175px;
}

.react-datepicker__close-icon,
.react-datepicker__close-icon::before,
 .react-datepicker__close-icon::after {
   background-color: #ccc;
 } */

.App-logo {
  /* height: 10vmin; */
  /* pointer-events: none; */
  align-self: flex-start;
  margin-left: 8px;
  margin-top : 8px;
  margin-bottom : 8px;
}

/* 
.search-dropdownXXX {
  position: relative; or position: absolute; 
  z-index: 100 !important;
}   
*/

/* ReactSearchAutocomplete - Component: force dropdown on top */
div div.wrapper {
  z-index:10 !important;  
}

.rsac {
  border-width: 2px;
}

.rsac_tid {
  height: 28px;
  /* border: black solid 2px; */
  /* border-width: 2px; */
  position:relative;
  z-index: 11 !important;  
  vertical-align: middle;
  margin-top: 1px;
  margin-bottom: 1px;
}

.rsac_eid {
  height: 28px;
  min-width: 300px;
 /* border: black solid 2px; */
 /* border-width: 2px; */
  position:relative;
  z-index: 10 !important; 
  /* vertical-align: middle; */
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 6px;
}


/* .ui-autocomplete {
  z-index: 100 !important;
} */
/*
div {
  background-color: crimson; 
  z-index: 9999999 !important;
}
*/

.App-header {
  /*background-color: #d7e2f8; */
  background-color: #3b4248;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  /* font-size: calc(10px + 2vmin); */
  color: black;  
}
.App-title {
  align-self: flex-start;
  margin-left: 2em;
  vertical-align: middle;
  border-style: solid;
}

.App-link {
  color: #61dafb;
}
 
.header-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style:none;
} 

/* Table auf Startpage */
.sp_table_div {
  display:flex;
  justify-content: center;
}
.sp_table {
  /* border:1px solid black; */
  width:80%;
  align-self: center;
}
.sp_table_label {
  border-right:1px solid black;
  text-align:right;
  padding-right: 5%;
  width: 50%;
}
.sp_table_data {
  text-align: left;
  padding-left: 5%;
}

.scroll-container {
  height: 800px;
  overflow-y: scroll;
 }

 .table-fixed-header {
  overflow-y: auto;
  /* height: 800px; */
}
.table-fixed-header thead th {
  background: #eee;
  position: sticky;
  top: 0;
  /* box-shadow: inset 1px 1px #000, 0 1px #000; */
}
.table-fixed-header td {
  padding-left: 4px;
}

/* .table-fixed-header-table {
  border-collapse: collapse;
  width: 100%;
} */


 
/* th,

.tableFixHead,
.tableFixHead td {
  box-shadow: inset 1px -1px #000;
}*/

/* Table auf Suchen Massnahme */
.sm_table_div {
  display:flex;
  justify-content: center;
}

.sm_table {  
  width:100%;
  align-self: center;  
  border-collapse: separate; /* collapse; */  
}

.data_table_div {
  display:block;
  justify-content: left;
}

.data_table {  
  width:100%;
  align-self: left;
}

.sm_table_report {   
  width: 100%;
  min-height: 567px;
  padding: 8px;
  margin:8px;  
  border-collapse: collapse; 
}


.sm_table_90 {
  width:90%;
  border-collapse: separate; /* collapse; */   
}
.sm_table_80 {
  width:80%;  
  border-collapse: separate; /* collapse; */  
}
.sm_table_70 {
  width:70%;
  align-self:center;
  border-collapse: separate; /* collapse; */  
}
.sm_table_60 {
  display: table;
  width:60%;    
  border-collapse: separate; /* collapse; */ 
}
.sm_table_detail_div {  
  vertical-align: top;
}

.sm_table_detail {  
  border:1px solid blue;
  width:100%;
  vertical-align: top;
  border-collapse: collapse; 
}

.box__icon {
   width : 50px;
   height : 43px;   
   fill: red;
   display: block;
   margin-bottom: 40px;
    /* width: 100%;
    height: 80px; */
    /* fill: #92b0b3; */
    /* display: block; */
    /* margin-bottom: 40px; */
}

.w5 { width: 5%; }
.w10 {  width: 10%; }
.w15 {  width: 15%; }
.w20 {  width: 20%; }
.w25 {  width: 25%; }
.w30 {  width: 30%; }
.w35 {  width: 35%; }
.w40 {  width: 40%; }
.w50 {  width: 50%; }
.w60 {  width: 60%; }
.w70 {  width: 70%; }
.w80 {  width: 80%; }
.w90 {  width: 90%; }

.al { text-align: left; }
.ar { text-align: right; }

.pr1 { padding-right: 1px; }
.pr2 { padding-right: 2px; }
.pr3 { padding-right: 3px; }
.pr4 { padding-right: 4px; }
.pr5 { padding-right: 5px; }
.pr6 { padding-right: 6px; }
.pr8 { padding-right: 8px; }
.pr10 { padding-right: 10px; }
.pr12 { padding-right: 12px; }
.pr15 { padding-right: 15px; }
.pr20 { padding-right: 20px; }
.pr25 { padding-right: 25px; }
.pr30 { padding-right: 30px; }
.pr40 { padding-right: 40px; }

.pl1 { padding-left: 1px; }
.pl2 { padding-left: 2px; }
.pl3 { padding-left: 3px; }
.pl4 { padding-left: 4px; }
.pl5 { padding-left: 5px; }
.pl6 { padding-left: 6px; }
.pl8 { padding-left: 8px; }
.pl10 { padding-left: 10px; }
.pl12 { padding-left: 12px; }
.pl15 { padding-left: 15px; }
.pl20 { padding-left: 20px; }
.pl25 { padding-left: 25px; }
.pl30 { padding-left: 30px; }
.pl40 { padding-left: 40px; }


/* 
table {
  border-width: 1px;
  border-color: darkblue;
  border-style: solid;   
}
*/

/*
td {   
  
  border-width: 1px;
  border-color: green; 
  border-style: solid; 
} 
*/
/*
th {  
  border-width: 1px;  
  border-color: blue; 
  border-style: solid;
}
*/



.th_sortable {
  cursor : default;
}


 /* option[value='cherry'] {
  background-color: red;
} */

/* .red_option
{
  background-color: red;
} */

option:not(:checked) {
  background-color: #fff;
}


/* select {
  background-color: red;
} */
/* 
option {
  font-family: serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 3;
  padding: 10px;
}

option:hover {
  background: black;
  color: red;
}

option:active {
  background: yellow;
  color: blue;
}

option:focus {
  background: green;
  color: orange;
} */

/* option:selected {
  background: red;
  color: yellow;
} */

/* select option:checked {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
}
select option:hover {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
  color: #fff;
} */

/* select.red_text, option:hover, option:checked, option:focus {
  box-shadow: 0 0 10px 100px #24a818 inset;
  border-color: blueviolet;
  background-color: red;
} */

/* option[aria-selected=true] {
  background-color: red;
}

select:focus option:checked {
  background: red linear-gradient(0deg, red 0%, red 100%);
} */


/* select option:checked {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
}
select option:hover {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
  color: #fff;
} */
/* select option {
  padding: 8px;
}
select {
  z-index: 1800; 
  position: absolute; 
  background: #fff; 
  height: 33px; 
  overflow: hidden; 
  width: 30%;
  outline: none;
} */

.td_button
{
  padding-top: 0px;
  padding-bottom: 1px;
  padding-right : 10px;
  padding-left : 10px;     
}

.td_blank4
{
  padding-right: 4px;
}

.td_error
{
  font-weight: bold;
  color: #AA0404;
}

.td_label
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right : 10px;
  padding-left : 10px;  
  text-align: left;  
  max-width:290px;
  width:auto;
  min-width:10px;  
}

.td_label_right
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right : 10px;
  padding-left : 10px;  
  text-align: right;  
  max-width:290px;
  width:auto;
  min-width:10px;  
}

.td_label_left
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right : 10px;
  padding-left : 10px;  
  text-align : left;  
}

.td_label_center
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right : 10px;
  padding-left : 10px;  
  text-align : center;    
}

.td_input {  
  text-align: left;
  vertical-align: middle;  
  padding-top: 0px; 
  font: 14px Tahoma;
}

.td_datepicker {
  height: 20px;
  vertical-align: middle; 
  padding-top: 0px; 
  font: 14px Tahoma;
}

.td_select {
  height: 20px;
  text-align:justify;
  font: 14px Tahoma;
}

.td_maskinput {
  height: 20px;
  text-align:justify;
  font: 14px Tahoma;
}

.td_center {
  text-align : center;  
  vertical-align: middle;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right : 2px;
  padding-left : 2px;  
}

/* 
input[type="text"]:enabled {
  border-style: solid 1px;  
  background-color: royalblue;
}

input[type="text"]:disabled {
  border-style: dashed 1px;  
}

input[type="text"]:read-only {
  border-style: dashed 1px;  
}

input[type="select"]::selection {
  border-style: solid 1px;  
}

input[type="select"]:disabled {
  border-style: dashed 1px;  
}

input[type="select"]:read-only {
  border-style: dashed 1px;  
} */


.td_input input {   
   /* border-style: dotted; */
   /* margin-left: 5px; */
   /* margin-right: 5px; */
   /* border: 0px; */
   /* border-radius: 0px; */
   /* border-width: 1;
   border-left-color: #ddd;
   border-top-color: #ddd;      
   border-right-color: #ddd;
   border-bottom-color: #ddd; */
   /*height: 20px;*/
   width:100%; 
   box-sizing: border-box;      
} 

.td_input textarea {  
  padding:1px 5px 1px;
  /*font: 14px Tahoma;*/
  line-height:1.5;
  border-style: dotted;
  /* overflow: auto; */
  /* resize: none; */
  width : 100%;
  /* position: absolute; */
  /* left:0;  top:0; */
  height: 6em;
  box-sizing: border-box;
  display: block;
  /* height: auto; */
  /* overflow-y: hidden; */
}

.td_check {
  text-align:left;
  padding-left: 6px;
}

.td_select_fitwidth {
  padding-left: 8px;
  padding-right: 8px;  
  width: 1px; 
  white-space: nowrap;
  /* border: 1px red solid; */
  overflow: hidden;  
}

/* 
.select_readonly {
    // for Firefox 
    -moz-appearance: none;
    // for Safari, Chrome, Opera 
    -webkit-appearance: none;
    background-color: transparent;
    border-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right : 10px;
    padding-left : 10px;      
} */


 
.td_select select {  
  /*border-style: dotted;*/
  /*margin-left: 5px;*/
  /*margin-right: 5px;*/
  width:100%;    
  box-sizing : border-box;
} 

/*
.arrange-horizontally {
   overflow-x:scroll; 
}
*/

.arrange-horizontally > * {
  display:table-cell;

}

.exportbtn, .cancelbtn {
  /* background-color : hsla(120, 50, 50, 50); */
  min-width: 120px;   
  display: table-cell; /* inline-flex; */

  border-radius: 8px;
  background-color: #007858;   
  color: white;
  padding: 6px 6px 6px 6px;
  font-size: 14px;
  border: none; 
  align-items: center;
  text-align: center;
  vertical-align: middle;   
}

.adminbtn {
  background-color: red;
  color: black;
  border: black solid 2px;
}

.warnbtn {
  background-color: yellow;
  color: black;
  border: black solid 2px;
}

.btndefault {
  border: black solid 2px;
}

.exportbtn:hover { background-color: #3e8e41; } 

.cancelbtn:hover { background-color: #AA0404; }

.exportbtn:disabled { color: #ccc }


.th_fitwidth {
  padding-left: 8px;
  padding-right:8px;
  /* border: 1px blue solid; */
}

.td_fitwidth {
  padding-left: 8px;
  padding-right: 8px;
  width: 1px;    
  white-space: nowrap;
  /* border: 1px red solid; */
  overflow: hidden;  
}

.td_border {
  /* border-bottom: 1px solid green;   */
  border-top: 1px solid green;  
  /* border-left: 1px solid green; */
  /* border: 1px solid black;  */  
} 

.td_fitwidtherror { 
  color: tomato;
}

.td_fitwidthR {
  padding-left: 8px;
  padding-right: 8px;
  width: 1px;    
  white-space: nowrap;
  /* border: 1px red solid; */
  overflow: hidden;  
  text-align: right;
}

.table-blank-row {
  height: 10px;
}

.table-blank-row4 {
  height: 4px;
}

.react-datepicker-popper {
  z-index: 99 !important;
}


/*
tr:hover{
  background-color:gray;
  cursor:pointer;
  }
*/

/* DROP DOWN */
.nav-dropdown {
  height: 10vh;  
  display:flex;
  justify-content: space-around;
  align-items:center;
  width:30%;
  margin:auto;
  background: chartreuse;
}

.nav-dropdown button {
  background: none;
  border:none;
  text-decoration: none;
  font-size: 18px;
  cursor:pointer;
}
.nav-dropdown button:hover {
  color:crimson;
}

.nav-suche,
.nav-neu {
  position:relative;
}

.nav-suche button:focus + ul,
.nav-neu button:focus + ul {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
}

.nav-suche ul,
.nav-neu ul {
  position: absolute;
  left: 0px;
  background: white;
  margin-top: 10px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style:none;
  border-radius: 5px;
  opacity : 0;
  /* pointer-events: none; */
  transform: translateY(-10px);
  transition: all 0.4s ease;
}

.nav-suche li,
.nav-neu li {
  background-color:cyan;
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.nav-suche li:hover,
.nav-neu li:hover {
  background-color:crimson;
}

.nav-suche a,
.nav-neu a {
  color:black;
  text-decoration: none;
}

.reportitemtable {
  background-color: cornsilk;
  border: black solid 2px; 
  /* width : 80%;
  height: 80%; */
  /* margin: 1px;
  padding: 15px; */
}

.flex-container {
  display: flex;
  background-color:silver;
  flex-wrap: wrap;
  margin:10px;
  flex-direction: row;
  /* align-self: center; */
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px; 
  padding: 10px; 
  font-size: 14px;
  width: 30%; 
  height: 30%;
}

.duplicatecompare {
  height : 24px;  
  text-align: right;
  padding-right: 10px;
}

