
#context-menu {
    position: fixed;
    z-index:10000;
    width: 200px;
    background: #1b1a1a;
    transform: scale(0);
    transform-origin:top left;
}

#context-menu.active {
    transform: scale(1);
    transition: transform 200ms ease-in-out;
}

#context-menu .context-menu-item {
    padding : 8px 10px;
    font-size: 15px;
    color:#eee;
}

#context-menu .context-menu-item:hover {
    background: #555;
}

#context-menu .context-menu-item i {
    display: inline-block;
    margin-right: 5px;
}

#context-menu hr {
    margin: 2px 0px;
    border-color: #555;
}