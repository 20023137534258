 /* Dropdown Button */
 
 .hdropbtn {
    /* background-color: #04AA6D; */
    background-color: transparent;
    color: #bbb; /* white; */
    padding: 8px;
    font-size: 16px;
    border: none;    
  }

  .hdropbtnlogout {
    background-color: transparent; /* #AA0404; */
    color: #bbb; /* white; */
    padding: 8px;
    font-size: 16px;
    border: none;    
  }

  .hdropbtnlogout:hover {
    background-color: #007858; /* #3e8e41;  */
    color: white
  } 
  
  /* The container <div> - needed to position the dropdown content */
  .hdropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .hdropdown-content {
    display: none;
    position: absolute;
    background-color: #3b4248; /* #f1f1f1; */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 21;
  }
  
  /* Links inside the dropdown */
  .hdropdown-content a {
    color: white; /* black; */
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .hdropdown-content a:hover {
    background-color: #777;
  }
  
  /* Show the dropdown menu on hover */
  .hdropdown:hover .hdropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .hdropdown:hover .hdropbtn {
  /*  background-color: #3e8e41; */
    color: white;
  } 