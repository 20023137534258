#holder {
    border: 2px dashed #ccc;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right : 10px;
    padding-left : 10px; 
    height: 220px;
    /* width:300px;  */
    min-width: 240px;
    box-sizing: border-box; 
  }
  
  #holder .hover { border: 10px dashed #0c0 !important; }
  